
::v-deep {
    .v-radio {
        &.v-item--active {
            .v-label {
                color: var(--v-grey-darken4);
            }
        }
    }
}
